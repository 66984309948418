<script lang="ts">
  import dayjs from 'dayjs/esm/index.js';
  import { dateString } from 'shared/src/utils/date.utils';
  import { createEventDispatcher, onMount } from 'svelte';

  const format = 'YYYY-MM-DD';
  export let value: Date | null | undefined = undefined;
  type Digit = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  export let defaultTime: `${0 | 1 | 2 | ''}${Digit}:${Digit}${Digit}` = '23:59';
  export let id: string | undefined = undefined;
  export let min: Date | undefined = undefined;
  export let max: Date | undefined = undefined;

  export let isValid: boolean = true;

  const hardMin = '1000-01-01';
  const hardMax = '9999-12-31';

  let clazz: string = '';
  export { clazz as class };

  interface $$Events {
    change: CustomEvent<Date>;
  }

  const dispatch = createEventDispatcher<$$Events>();

  let internalValue: string;
  const onInput = (x: Date | null | undefined) => {
    if (!x) internalValue = '';
    else internalValue = dayjs(x).format(format);
  };

  const onOutput = (x: string) => {
    if (!x) {
      value = undefined;
      return;
    }
    const [defaultHours, defaultMinutes] = defaultTime.split(':');

    const referenceDate = value || new Date();
    const newDate = dayjs(x, format).toDate();
    newDate.setHours(value ? referenceDate.getHours() : Number(defaultHours));
    newDate.setMinutes(value ? referenceDate.getMinutes() : Number(defaultMinutes));
    newDate.setSeconds(value ? referenceDate.getSeconds() : 0);
    newDate.setMilliseconds(value ? referenceDate.getMilliseconds() : 0);
    value = newDate;
    // @ts-ignore
    dispatch('change', value);
  };

  $: onInput(value);
  $: onOutput(internalValue);

  let minString = min ? dayjs(min).format('YYYY-MM-DD') : hardMin;
  let maxString = max ? dayjs(max).format('YYYY-MM-DD') : hardMax;

  let dateInput: HTMLInputElement;
  let validationErrorMessage = '';
  $: if (min || max || value) {
    validateDate();
  }

  onMount(validateDate);

  function validateDate() {
    if (typeof window !== 'undefined' && dateInput) {
      // If we have no span, it's valid:

      minString = min ? dayjs(min).format('YYYY-MM-DD') : hardMin;
      maxString = max ? dayjs(max).format('YYYY-MM-DD') : hardMax;

      const tooEarly = minString > dayjs(value).format('YYYY-MM-DD');
      const tooLate = maxString < dayjs(value).format('YYYY-MM-DD');

      if (!tooEarly && !tooLate) {
        validationErrorMessage = '';
      } else {
        if (min && max) {
          validationErrorMessage = `Must be between ${dateString(min)} and ${dateString(max)}.`;
        } else if (min) {
          validationErrorMessage = `Must be ${dateString(min)} or later.`;
        } else if (max) {
          validationErrorMessage = `Must be before ${dateString(max)}.`;
        } else {
          validationErrorMessage = `Must be between ${hardMin} and ${hardMax}`;
        }
      }
      dateInput.setCustomValidity(validationErrorMessage);
      const inputIsValid = dateInput.reportValidity();
      isValid = !validationErrorMessage && inputIsValid;
    }
  }
</script>

<input
  min={minString || hardMin}
  max={maxString || hardMax}
  bind:this={dateInput}
  {id}
  class={clazz}
  class:error={!!validationErrorMessage}
  type="date"
  bind:value={internalValue}
/>

<style lang="postcss">
  .error {
    @apply border border-red outline-red;
  }
</style>
